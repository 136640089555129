import React from "react"
import Img from "gatsby-image"
import{graphql} from "gatsby"
import Layout from "../components/layout"
import style from "./services.module.css"
import {Link} from "gatsby"


const ServicesPage = ({data}) => {
    const { allImageContent, Services } = data
  return (
    <Layout>
      <section className={style.page}>
      {Services.edges.map(item => {
          const imageforServices = allImageContent.edges.find(
            x => x.node.fluid.originalName === item.node.image.src
          )
          return (
            <article key={item.node.id}>
              
              <Link to={item.node.link}> <Img fluid={imageforServices.node.fluid}/> </Link>
              <h3 className={style.h3}>
                {item.node.content}
              </h3>
              </article>
            
          )
    })}
    
  </section>  
  </Layout>
    )
    }
export default ServicesPage
export const pagequery = graphql`
  query {
    Services: allServicesJson {
        edges {
          node {
            id
            link
            content
            image {
              src
            }
          }
        }
      }
      allImageContent: allImageSharp {
        edges {
          node {
            fluid(pngQuality: 80) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `