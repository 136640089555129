import React from 'react'
import Navbar from "./Navbar"
import Images from "../components/image"
import Footer from "./footer"


const Layout = ({children}) => {
    return (
       <>
       <Navbar/>
       <Images/>
       <main>
    
           {children}
       </main>
       <Footer />

       </>
    );
};

export default Layout;