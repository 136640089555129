import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import {Link} from "gatsby"


const LogoWrap = styled.div`
   /* margin: auto 0;  */
   margin-top:200px;
  flex: 0 1 100px; 
  height:156vh;
  width:100%;

  
  @media (max-width: 768px) and (orientation: landscape) {
  flex: 0 1 25px; 
  }
`
const Images = () => {
    const data = useStaticQuery(graphql`
      query {
        file(name: { eq: "header" }, extension: { eq: "png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, pngQuality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
  return (
      <LogoWrap as={Link} to="/">
      <Img fluid={data.file.childImageSharp.fluid} alt="logo" />
      </LogoWrap>
  )
}

export default Images